export const OnboardingModule = {
    state: () => ({
        activeOnboardingStep: 0,
    }),
    getters: {
        activeOnboardingStep(state) {
            return state.activeOnboardingStep;
        },
    },
    mutations: {
        activeOnboardingStep(state, newVal) {
            state.activeOnboardingStep = newVal;
        },
    },
    actions: {

    },
}