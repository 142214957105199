import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from '../../../main';
import { firebaseAuthActions } from './signUpAndSignInActions';
import { getAuth, RecaptchaVerifier, createUserWithEmailAndPassword, signInWithPhoneNumber, sendEmailVerification } from 'firebase/auth';
import { signInWithEmailAndPassword } from 'firebase/auth';

export const actions = {

    onAuthStateChanged(context) {
        const auth = getAuth();
        auth.onAuthStateChanged(function(user) {
            if (user) {
                context.commit("setCurrentUser", user);
            } else {
                context.commit("setCurrentUser", null);
            }
        });
    },
    verifyAuth(context) {
        // returns true if the user is authenticated, false if not
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            context.commit("setCurrentUser", user);
        }
        return !!user;
    },
    initReCaptcha() {
        setTimeout(() => {
            const auth = getAuth();
            window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                    console.log({ response });
                },
            }, auth);
            window.appVerifier = window.recaptchaVerifier
        }, 1000);
    },
    submitOTP(context, code) {
        return new Promise((resolve, reject) => {
            window.confirmationResult.confirm(code).then((result) => {
                // User signed in successfully.
                const user = result.user;
                context.commit("setCurrentUser", user);
                resolve(user);
            }).catch((error) => {
                reject(error.message);
            });
        });
    },
    resendOTP(context) {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            signInWithPhoneNumber(auth, context.state.emailOrPasswordInput, window.appVerifier)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                    resolve()
                }).catch((error) => {
                    console.log("Error; SMS not sent", error);
                    reject(error);
                });
        });
    },

    async updateUserDocument(context, data) {
        const currentUserId = context.getters['getCurrentUserFromFirebase'].uid;
        const userDocRef = doc(db, "users", currentUserId);
        await setDoc(userDocRef, data, { merge: true });
        // grab the new updated doc
        await context.dispatch("fetchUserFSDocument");
    },
    async fetchUserFSDocument(context) {
        const currentUser = await context.getters['getCurrentUserFromFirebase'];
        if (currentUser) {
            const currentUserId = currentUser.uid;
            const userDocRef = doc(db, "users", currentUserId);
            const docSnap = await getDoc(userDocRef);
            if (docSnap.exists()) {
                context.commit('setUserFSDocument', docSnap.data())
                return docSnap.data();
            }
        } else {
            return null;
        }


    },
    async fetchUserFSDocumentByUID(_, uid) {
        if (!uid) {
            return null;
        }
        const userId = uid;
        const userDocRef = doc(db, "users", userId);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            return null;
        }
    },
    signUpToFirebaseByEmail(context, credentials) {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            createUserWithEmailAndPassword(auth, credentials.email, credentials.password)
                .then(
                    (userCredential) => {
                        const user = userCredential.user;
                        context.commit("setCurrentUser", user);
                        // send email verification
                        sendEmailVerification(user)
                        resolve(userCredential.user);
                    })
                .catch((error) => {
                    reject(error.message);
                });
        });
    },
    signInToFirebaseByEmail(context, credentials) {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            signInWithEmailAndPassword(auth, credentials.email, credentials.password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    context.commit("setCurrentUser", user);
                    resolve();
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    console.log(errorMessage);
                    reject(errorMessage);
                });
        });
    },

    ...firebaseAuthActions,

};