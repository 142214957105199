const userUnprotectedRoutes = [

    //? routes that has app bar, drawer, footer
    {
        path: '',
        component: () =>
            import ("@/layouts/NormalLayout.vue"),
        children: [{
                path: 'on-boarding',
                name: 'Onboarding',
                component: () =>
                    import ('@/views/Onboarding.vue')
            },
            {
                path: 'choosePlan',
                name: 'ChoosePlan',
                component: () =>
                    import ('@/views/choose-plan/ChoosePlan.vue')
            },
            {
                path: 'signUp',
                name: 'SignUp',
                component: () =>
                    import ('@/views/auth/sign-up/SignUp.vue')
            }, {
                path: 'signIn',
                name: 'SignIn',
                component: () =>
                    import ('@/views/auth/sign-in/SignIn.vue')
            }, {
                path: 'phoneSignUp',
                name: 'PhoneSignUp',
                component: () =>
                    import ('@/views/auth/sign-up/PhoneSignUp.vue')
            },
            {
                path: 'emailSignUp',
                name: 'EmailSignUp',
                component: () =>
                    import ('@/views/auth/sign-up/EmailSignUp.vue')
            },
            {
                path: 'phoneSignIn',
                name: 'PhoneSignIn',
                component: () =>
                    import ('@/views/auth/sign-in/PhoneSignIn.vue')
            },
            {
                path: 'emailSignIn',
                name: 'EmailSignIn',
                component: () =>
                    import ('@/views/auth/sign-in/EmailSignIn.vue')
            },
        ]
    },
    //? toutes that has full screen layout (no layout)
    {
        path: 'calculator',
        name: 'Calculator',
        component: () =>
            import ( /* webpackPrefetch: true */ '@/views/calculator/Calculator.vue')
    }, {
        path: 'loadingScreen',
        name: 'LoadingScreen',
        component: () =>
            import ('@/views/calculator/LoadingScreen.vue')

    },
    {
        path: 'badgePreview/:uid',
        name: 'BadgePreview',
        component: () =>
            import ('@/views/badge-preview/BadgePreview.vue')

    }
];

export default userUnprotectedRoutes;