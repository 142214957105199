export const mutations = {
    setCurrentUser(state, user) {
        state.currentUser = user;
    },
    setEmailOrPhoneNumber(state, newVal) {
        state.emailOrPhoneNumber = newVal;
    },
    setUserFSDocument(state, newVal) {
        state.userFSDocument = newVal;
    }

};