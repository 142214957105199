<template>
  <div>
    <nprogress-container></nprogress-container>
    <router-view />
    <v-snackbar
      v-model="getSnackbar.isSnackbarOpen"
      :color="getSnackbar.color"
      :timeout="5000"
    >
      {{ getSnackbar.message }}
    </v-snackbar>
    <FeedbackDialog />
  </div>
</template>


<script>
import { getAuth } from "firebase/auth";
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
import FeedbackDialog from "@/components/global/dialogs/FeedbackDialog.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    NprogressContainer,
    FeedbackDialog,
  },
  async mounted() {
    console.log(process.env);
    // scroll up
    window.scrollTo(0, 1);
    // fire event listener to change state of authentication
    await this.initCurrentUser();
    this.$store.dispatch("onAuthStateChanged");
  },
  computed: {
    ...mapGetters(["getSnackbar", "getFeedbackDialogData"]),
  },
  methods: {
    initCurrentUser() {
      // get the user from the firebase auth SDK
      return new Promise((resolve) => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged((user) => {
          unsubscribe();
          if (user) this.$router.push({ name: "CustomerDashboard" });
          resolve(user);
        }, resolve(null));
      });
    },
  },
};
</script>


<style >
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;900&display=swap");
* {
  /* this will disable zoom on double tap */
  touch-action: manipulation;
  text-decoration: none;
}
.unselectable {
  /* this will make all texts unselectable */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-application {
  font-family: "League Spartan", sans-serif;
}

.spartan-font {
  font-family: "League Spartan", sans-serif;
}
.app-bar-title {
  color: #333333;
  font-size: 16px;
  font-weight: 900;
}

.cta-button {
  background: linear-gradient(262.97deg, #00b08d 0%, #00e9bb 100%);
}
.linear-green-background {
  background-color: linear-gradient(262.97deg, #00b08d 0%, #00e9bb 100%);
}
.cta-button span {
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
}

.button-active {
  color: #fff !important;
  font-weight: 900 !important;
  background-color: #00b08d !important;
}

.text-header {
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  line-height: 45px;
  background: linear-gradient(262.97deg, #00b08d 0%, #00e9bb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.text-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
}
.text-underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}
</style>