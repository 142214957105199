import { state } from "./state";
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions/index';

export const AuthModule = {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
}