import Vue from 'vue'
import Vuex from 'vuex'
import { CalculatorModule } from './calculator-module/CalculatorModule';
import { OnboardingModule } from './onBoarding-module/OnboardingModule';
import { AuthModule } from './auth-module/index';
import { UIModule } from './ui-module/UIModule';
import { MediaModule } from './media-module/index';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isDrawerOpen: false,
    },
    mutations: {
        isDrawerOpen(state, newVal) {
            state.isDrawerOpen = newVal;
        },
    },
    getters: {
        isDrawerOpen(state) {
            return state.isDrawerOpen;
        },
    },
    actions: {},
    modules: {
        CalculatorModule,
        OnboardingModule,
        AuthModule,
        UIModule,
        MediaModule
    }
})