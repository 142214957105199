import { getAuth } from "firebase/auth";

export const getters = {
    getCurrentUser(state) {
        return state.currentUser;
    },
    getCurrentUserFromFirebase(state) {
        if (state.currentUser) {
            return state.currentUser;
        }
        // get the user from the firebase auth SDK
        return new Promise((resolve) => {
            const auth = getAuth();
            const unsubscribe = auth.onAuthStateChanged(user => {
                unsubscribe();
                resolve(user);
            });
        });
    },
    getEmailOrPhoneNumber(state) {
        return state.emailOrPhoneNumber;
    },
    getUserFSDocument(state) {
        return state.userFSDocument;
    }

};