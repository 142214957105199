import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'animate.css';
import Flag from 'vue-flagpack'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { alertsService } from './services/AlertsService';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firestorePlugin } from 'vuefire'
import { firebaseConfig } from './configs/firebaseConfig';
import { getFirestore } from 'firebase/firestore';
import NProgress from 'vue-nprogress'
import { browserLocalPersistence, getAuth, setPersistence } from 'firebase/auth';
import { StripePlugin } from '@vue-stripe/vue-stripe';
import { stripConfig } from './configs/stripeConfig';
import Hotjar from '@hotjar/browser';
import './registerServiceWorker'

const siteId = process.env.VUE_APP_HOTJAR_ID;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);


// global variables
Vue.prototype.$alertsService = alertsService;

// stripe
Vue.use(StripePlugin, stripConfig);

// NProgress
Vue.use(NProgress)
const nprogress = new NProgress()
const nprogressOptions = {
    latencyThreshold: 200,
    router: true,
    http: false
};
Vue.use(NProgress, nprogressOptions)


// Firebase initialization
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const auth = getAuth();
setPersistence(auth, browserLocalPersistence)

getAnalytics(app);
Vue.use(firestorePlugin)

// flags library
Vue.use(Flag, {
    name: 'Flag'
});

// axios
Vue.use(VueAxios, axios)

// Vue init
Vue.config.productionTip = false
new Vue({
    router,
    store,
    vuetify,
    nprogress,
    render: h => h(App)
}).$mount('#app')