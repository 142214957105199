import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import userProtectedRoutes from './userProtectedRoutes'
import userUnprotectedRoutes from './userUnprotectedRoutes'
import { checkIfUserInfoCompleted } from '../utilities/checkIfUserInfoCompleted';
Vue.use(VueRouter)

const routes = [
    // default route
    {
        path: '/',
        redirect: '/on-boarding'
            // redirect: 'user/choosePaymentMethod'
    },
    // public routes (no authentication needed)
    {
        path: "/",
        component: () =>
            import ("@/layouts/EmptyRouterView.vue"),
        children: userUnprotectedRoutes
    },
    // protected routes (user role level is needed to access)
    {
        path: "/user",
        meta: {
            requiresAuthenticatedUser: true,
        },
        component: () =>
            import ("@/layouts/EmptyRouterView.vue"),
        children: userProtectedRoutes
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async(to, from, next) => {

    //? scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);

    //? check if user authenticated
    const authenticatedUser = await store.getters["getCurrentUserFromFirebase"];

    //? protected routes middlewares
    //user role level check 
    if (to.matched.some(record => record.meta.requiresAuthenticatedUser)) {
        if (!authenticatedUser) {
            next({
                // User login
                name: 'SignIn',
            });
        }

        // make sure user completed his registration process
        const userDocument = await store.dispatch('fetchUserFSDocument');
        if (to.name != 'AboutYou' && !checkIfUserInfoCompleted(userDocument)) {
            next({ name: "AboutYou" });
        }
    }


    //. if no condition was matched, continue to next route
    next();

});
export default router