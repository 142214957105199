import { ApiService } from '../../services/ApiService';
import { ANNUAL_AVERAGE_EMISSIONS, ONE_TON_OF_CARBON_PRICE, TREES_COST } from '../../constants/calculator';
export const CalculatorModule = {
    state: () => ({
        annualAverageEmission: ANNUAL_AVERAGE_EMISSIONS,
        oneTonOfCarbonPrice: ONE_TON_OF_CARBON_PRICE,
        treesCost: TREES_COST,
        activeCalculatorStepNumber: 0,
        calculatorSteps: [{
                number: 1,
                img: require("@/assets/Question-bg-1.png"),
            },
            {
                number: 2,
                img: require("@/assets/Question-bg-2.png"),
            },
            {
                number: 3,
                img: require("@/assets/Question-bg-3.png"),
            },
            {
                number: 4,
                img: require("@/assets/Question-bg-4.png"),
            },
            {
                number: 5,
                img: require("@/assets/Question-bg-5.png"),
            },
            {
                number: 6,
                img: require("@/assets/Question-bg-6.png"),
            },
            {
                number: 7,
                img: require("@/assets/Question-bg-7.png"),
            },
            {
                number: 8,
                img: require("@/assets/Question-bg-8.png"),
            }
        ],
        userInputs: {
            country: '',
            milesDriven: 0,
            carburant: null,
            shortHaulFlightsNumber: 0,
            mediumHaulFlightsNumber: 0,
            longHaulFlightsNumber: 0,
            publicTransportUse: 0,
            meatFrequency: null,
            shoppingAmount: 0,
            foodAmount: 0,
            clothesAmount: 0,
            occupantsNumber: 1,
            homeSize: null,
            homePoweredByRE: null,
        },
        carbonEmissions: 0,
        chosenPlan: null,
    }),
    getters: {
        activeCalculatorStepNumber(state) {
            return state.activeCalculatorStepNumber;
        },
        activeCalculatorStep(state) {
            return state.calculatorSteps[state.activeCalculatorStepNumber];
        },
        calculatorSteps(state) {
            return state.calculatorSteps;
        },
        getUserInputs(state) {
            return state.userInputs;
        },
        getCarbonEmissions(state) {
            if (state.carbonEmissions) {
                return state.carbonEmissions;
            } else {
                return parseFloat(localStorage.getItem('carbonEmissions'));
            }
        },
        getAnnualAverageEmission(state) {
            return state.annualAverageEmission;
        },
        getOneTonOfCarbonPrice(state) {
            return state.oneTonOfCarbonPrice;
        },
        getTreesCost(state) {
            return state.treesCost;
        },
        getChosenPlan(state) {
            if (state.chosenPlan) {
                return state.chosenPlan;
            } else {
                return JSON.parse(localStorage.getItem('chosenPlan'));
            }
        },
        getMonthlyCost(state) {
            if (!localStorage.getItem('carbonEmissions')) return null;
            return (
                (state.oneTonOfCarbonPrice * localStorage.getItem('carbonEmissions')) / 12 +
                state.treesCost
            ).toFixed(2);
        },
    },
    mutations: {
        activeCalculatorStep(state, newVal) {
            state.activeCalculatorStep = newVal;
        },
        activeCalculatorStepNumber(state, newVal) {
            state.activeCalculatorStepNumber = newVal;
        },
        nextCalculatorStep(state) {
            if (state.activeCalculatorStepNumber < state.calculatorSteps.length - 1)
                state.activeCalculatorStepNumber++;
        },
        previousCalculatorStep(state) {
            // make sure this current step is not the first, to dont access an undefined index in the array 
            if (state.activeCalculatorStepNumber > 0)
                state.activeCalculatorStepNumber--;
        },
        setUserInputs(state, { key, value }) {
            state.userInputs[key] = value;
        },
        setCarbonEmissions(state, newVal) {
            state.carbonEmissions = newVal;
            localStorage.setItem("carbonEmissions", newVal);
        },
        setChosenPlan(state, newVal) {
            state.chosenPlan = newVal;
            localStorage.setItem("chosenPlan", JSON.stringify(newVal));
        },
        resetUserInputs(state) {
            state.userInputs = {
                country: '',
                milesDriven: 0,
                carburant: null,
                shortHaulFlightsNumber: 0,
                mediumHaulFlightsNumber: 0,
                longHaulFlightsNumber: 0,
                publicTransportUse: 0,
                meatFrequency: null,
                shoppingAmount: 0,
                foodAmount: 0,
                clothesAmount: 0,
                occupantsNumber: 1,
                homeSize: null,
                homePoweredByRE: null,
            };
        }
    },
    actions: {
        async calculateCarbonEmission(context) {
            // get user inputs
            let dataToSend = context.getters.getUserInputs;
            //change unity from ie: 4K = 4000
            dataToSend.milesDriven = dataToSend.milesDriven * 1000;

            //? set default values

            // any amount skiped is set to 250 
            if (dataToSend.shoppingAmount == 0) {
                dataToSend.shoppingAmount = 250;
            }
            if (dataToSend.foodAmount == 0) {
                dataToSend.foodAmount = 250;
            }
            if (dataToSend.clothesAmount == 0) {
                dataToSend.clothesAmount = 250;
            }

            // set the amounts that passed the max value 
            if (dataToSend.shoppingAmount == 500) {
                dataToSend.shoppingAmount = 700;
            }
            if (dataToSend.foodAmount == 500) {
                dataToSend.foodAmount = 800;
            }
            if (dataToSend.clothesAmount == 500) {
                dataToSend.clothesAmount = 1000;
            }

            // if user skips occupants number set it to 1
            if (dataToSend.occupantsNumber == 0) {
                dataToSend.occupantsNumber = 1;
            }

            // since flights max number in slider is (6) that show +5 
            // we need to turn it into 7
            if (dataToSend.shortHaulFlightsNumber == 6) {
                dataToSend.shortHaulFlightsNumber = 7;
            }
            if (dataToSend.mediumHaulFlightsNumber == 6) {
                dataToSend.mediumHaulFlightsNumber = 7;
            }


            const response = await ApiService.post('/calculator', context.getters.getUserInputs);
            context.commit('setCarbonEmissions', response.data);
            return response.data;
        },
        async sendUpdatePlan(_, planData) {
            return await ApiService.post('/payment/changePlan', planData);
        }
    },
}