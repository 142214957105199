export const UIModule = {
    state: () => ({
        snackbar: {
            color: null,
            message: "",
            isSnackbarOpen: false,
        },
        feedbackDialogData: {
            isVisible: false,
            type: null,
            title: "",
            description: "",
        },
        isYourForestDialogOpen: false,
        currentAppBarTitle: "",

    }),
    getters: {
        getSnackbar(state) {
            return state.snackbar;
        },
        getFeedbackDialogData(state) {
            return state.feedbackDialogData;
        },
        getIsYourForestDialogOpen(state) {
            return state.isYourForestDialogOpen;
        },
        getCurrentAppBarTitle(state) {
            return state.currentAppBarTitle;
        }
    },
    mutations: {
        setSnackbar(state, newVal) {
            state.snackbar = newVal;
        },
        showFeedbackDialogData(state, newVal) {
            newVal.isVisible = true;
            state.feedbackDialogData = newVal;
        },
        hideFeedbackDialog(state) {
            state.feedbackDialogData.isVisible = false;
            state.feedbackDialogData = {...state.feedbackDialogData };
        },
        setSuccessSnackbarMessage(state, message) {
            state.snackbar.message = message;
            state.snackbar.color = "#6BCB77";
            state.snackbar.isSnackbarOpen = true;
        },
        setErrorSnackbarMessage(state, message) {
            state.snackbar.message = message;
            state.snackbar.color = "#EB5353";
            state.snackbar.isSnackbarOpen = true;
        },
        setIsYourForestDialogOpen(state, newVal) {
            state.isYourForestDialogOpen = newVal;
        },
        setCurrentAppBarTitle(state, newVal) {
            state.currentAppBarTitle = newVal;
        }
    },

}