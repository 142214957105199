const userProtectedRoutes = [{
    path: '',
    component: () =>
        import ("@/layouts/NormalLayout.vue"),
    children: [{
            path: 'aboutYou',
            name: 'AboutYou',
            component: () =>
                import ('@/views/auth/sign-up/AboutYou.vue')
        },
        {
            path: 'choosePaymentMethod',
            name: 'ChoosePaymentMethod',
            component: () =>
                import ('@/views/payment/ChoosePaymentMethod.vue')
        }, {
            path: 'sucessfullPayment',
            name: 'SucessfullPayment',
            component: () =>
                import ('@/views/payment/SucessfullPayment.vue')
        }, {
            path: 'customerDashboard',
            name: 'CustomerDashboard',
            component: () =>
                import ('@/views/customer-dashboard/CustomerDashboard.vue')
        },
    ]
}];

export default userProtectedRoutes;