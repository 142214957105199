import { FacebookAuthProvider, getAuth, GoogleAuthProvider, OAuthProvider, sendEmailVerification, sendPasswordResetEmail, signInWithPhoneNumber, signInWithPopup, signOut } from "firebase/auth";
import router from "../../../router";

export const firebaseAuthActions = {

    signUpOrSignInToFirebaseByPhoneNumber(context) {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            signInWithPhoneNumber(auth, context.getters['getEmailOrPhoneNumber'], window.appVerifier)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                    resolve()
                }).catch((error) => {
                    console.log("Error; SMS not sent", error);
                    reject(error);
                });
        });
    },

    signUpOrSignInUsingGoogleProvider(context) {
        return new Promise((resolve, reject) => {
            const provider = new GoogleAuthProvider();

            const auth = getAuth();

            signInWithPopup(auth, provider)
                .then((result) => {
                    context.commit("setCurrentUser", result.user);
                    // send email verification
                    context.dispatch('sendEmailVerification', result.user);
                    resolve();

                }).catch((error) => {
                    reject(error);
                });
        });
    },

    signUpOrSignInUsingFacebookProvider(context) {
        return new Promise((resolve, reject) => {
            const provider = new FacebookAuthProvider();
            provider.addScope('user_birthday');
            const auth = getAuth();
            signInWithPopup(auth, provider)
                .then((result) => {
                    context.commit("setCurrentUser", result.user);
                    // send email verification
                    context.dispatch('sendEmailVerification', result.user);
                    resolve(result.user);

                }).catch((error) => {
                    reject(error);
                });
        });
    },

    signUpOrSignInUsingAppleProvider(context) {
        return new Promise((resolve, reject) => {
            const provider = new OAuthProvider('apple.com');
            provider.addScope('email');
            provider.addScope('name');
            const auth = getAuth();
            signInWithPopup(auth, provider)
                .then((result) => {
                    context.commit("setCurrentUser", result.user);
                    // send email verification
                    context.dispatch('sendEmailVerification', result.user);
                    resolve(result.user);
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    reject(errorMessage);
                });
        });
    },
    logout(context) {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            signOut(auth).then(() => {
                context.commit("setCurrentUser", null);
                router.push({ name: "SignIn" });
                resolve();
            }).catch((error) => {
                const errorMessage = error.message;
                reject(errorMessage);
            });
        });

    },
    sendEmailVerification(context, user) {
        // send email verification
        try {
            if (!user.emailVerified) {
                sendEmailVerification(user);
            }
        } catch (error) {
            context.commit('setErrorSnackbarMessage', error.message);
        }
    },
    async sendForgotPasswordEmail(context, email) {
        try {
            const auth = getAuth();
            await sendPasswordResetEmail(auth, email)
            context.commit('showFeedbackDialogData', {
                title: "Sent successfuly",
                description: "Password Resets Email Sent successfuly, check you SPAM as well !",
            });

        } catch (error) {
            context.commit('setErrorSnackbarMessage', error.message);
        }
    },

};