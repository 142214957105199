<template>
  <v-dialog :value="getFeedbackDialogData.isVisible" persistent width="100%">
    <div class="card-styles px-4 pb-4 pt-10 text-center">
      <v-img
        height="100"
        class="my-4"
        contain
        :src="require('@/assets/Dialog icons/Alert/check-circle-2.svg')"
      >
      </v-img>
      <div class="title-text">{{ getFeedbackDialogData.title }}</div>
      <div class="description-text py-3">
        {{ getFeedbackDialogData.description }}
      </div>
      <v-row class="col-12" justify="center">
        <v-btn class="close-button" depressed dark @click="hideFeedbackDialog"
          >close</v-btn
        >
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters(["getFeedbackDialogData"]),
  },
  methods: {
    ...mapMutations(["hideFeedbackDialog"]),
  },
};
</script>

<style >
.v-overlay__scrim {
  background-color: #FAFAFA !important;
}
</style>
<style lang="css" scoped>
.card-styles {
  position: absolute;
  bottom: 25px;
  background: rgba(255, 255, 255, 0.69) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(8px) !important;
  -webkit-backdrop-filter: blur(8px) !important;
  border-radius: 42px !important;
  width: 90% !important;
}

.title-text {
  font-family: "League Spartan", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 20px;
  background: linear-gradient(262.97deg, #00b08d 0%, #00e9bb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.description-text {
  font-family: "League Spartan", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #4f4f4f;
}

.close-button {
  padding: 8px 24px;
  width: 105px;
  height: 39px;
  background: linear-gradient(262.97deg, #00b08d 0%, #00e9bb 100%);
  border-radius: 32px;
}
</style>